import React from "react";
import Layout from "../layouts/en";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PressInfo from "../pressReleases";
import PressReleases from "../components/PressRelases";
import { Twitter, Facebook } from "react-social-sharing";
import Artist from "../components/Invited";
import Questions from "../components/QA";

const IndexPage = props => (
  <Layout location={props.location}>
    <section className="countdown">
      <div className="container">
        <div className="col-md-12" style={{ paddingBottom: "10%" }}>
          <div className="col-md-7">
            <div className="bs-example" data-example-id="responsive-embed-16by9-iframe-youtube">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item" 
                  src="https://www.youtube.com/embed/7zgMFcuPFHY"
                  allowFullScreen=""
                />
              </div>
              <p className="text-video">
                We believe that each of us has the power to change the world. Our way of doing it is through art and music.
              </p>
              <br />
              <div className="text-center">
                <button  className={"js-footer-donate-btn "}>
                  <FormattedMessage id="donateNow" />
                </button>
              </div>

              <br />
            </div>
          </div>
          <div className="col-md-5">
            <h1 className="t1">VENEZUELA IS SUFFERING</h1>
            <br />
            <ul className="status">
              <li>
                For years, Venezuelans suffered a deterioration in their quality of life, more than 250,000 Venezuelans have been murdered in the country in 
                the last 10 years, 33% of children living in poverty suffer from malnutrition and are at risk of delays. in his cognitive and psychomotor development. 
                Thousands of children have died due to lack of access to medicines and medical treatments. And more than 1,750,000 Venezuelans are in Colombia as migrants.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="moment">
      <div className="col-md-6" />
      <div className="container">
        <div className="col-md-12">
          <div className="col-md-8" style={{ paddingRight: 0 }}>
            <img src="/img/girl.jpg" alt="Es el momento de actuar" />
            <h3 className="title">
              <FormattedHTMLMessage id="timeToAct" />
            </h3>
            <hr />
            <FormattedHTMLMessage id="reasons" />
            <div className="col-md-12 text-center">
              <h3 className="subtitle">
                <FormattedMessage id="timeToActAction" />
              </h3>
              <br />
              <br />
              <button  className={"js-footer-donate-btn "}>
                <FormattedMessage id="donateNow" />
              </button>
            </div>
            <div className="col-md-12 social">
              <div className="col-md-6 text-right">
                <h3>
                  <FormattedMessage id="helpToShare" />
                </h3>
              </div>
              <div className="col-md-3 col-xs-6 text-right">
                <Twitter
                  solid
                  medium
                  message="This international concert will bring global attention to this unacceptable and preventable crisis and raise funds for essential humanitarian aid for Venezuelan refugees."
                  link="https://venezuelaaidlive.com/"
                />
              </div>
              <div className="col-md-3 col-xs-6 text-left">
                <Facebook solid medium link="https://venezuelaaidlive.com" />
              </div>
            </div>
          </div>

          <div className="col-md-4" style={{ paddingLeft: 0 }}>
            <Artist title={"Invited"} strong={"Artists"} />
          </div>
        </div>
      </div>
    </section>

    <section className="videos" style={{ paddingBottom: 35 }}>
      <div className="container">
        <div className="col-md-12">
          <FormattedHTMLMessage id="pressReleases" />
          <br />
        </div>
        <PressReleases releases={PressInfo["en"]} />
      </div>
    </section>

    <Questions langKey={"en"} />

    <section className="videos">
      <div className="container">
        <div className="col-md-12">
          <FormattedHTMLMessage id="newsFronVzla" />
          <br />
          <br />
        </div>
        <div className="col-md-12">
          <div className="col-md-4">
            <div
              className="bs-example"
              data-example-id="responsive-embed-16by9-iframe-youtube"
            >
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/JqtsgyzI0kw"
                  allowFullScreen=""
                />
              </div>
            </div>
            <h4>Why has 7% of the population fled the country?</h4>
          </div>
          <div className="col-md-4">
            <div
              className="bs-example"
              data-example-id="responsive-embed-16by9-iframe-youtube"
            >
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/XqYXzOSmcss"
                  allowFullScreen=""
                />
              </div>
            </div>
            <h4>The biggest EXODUS of 21st century?</h4>
          </div>
          <div className="col-md-4">
            <div
              className="bs-example"
              data-example-id="responsive-embed-16by9-iframe-youtube"
            >
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/i1SVMLUSWso"
                  allowFullScreen=""
                />
              </div>
            </div>
            <h4>Begging for food in Venezuela</h4>
          </div>
        </div>
      </div>
    </section>

    {/* <section className="videos" style={{ paddingBottom: 35 }}>
      <div className="container sponsorBy-block">
        <FormattedHTMLMessage id="mainBenefactorsText" />

        <div className="partners-list mb-5">
          <div className="partner">
            <img
              alt="Betcris"
              title="Betcris"
              src="/img/partners/betcrisen.png"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
          <div className="partner">
            <img
              alt="WeSend"
              title="WeSend"
              src="/img/partners/wesend.svg"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section className="videos" style={{ paddingBottom: 35 }}>
      <div className="container sponsorBy-block">
        <FormattedHTMLMessage id="sponsorByText" />

        <div className="partners-list mb-5">
          <div className="partner">
            <img
              alt="puntored"
              title="puntored"
              src="/img/partners/puntologo.png"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
          <div className="partner">
            <img
              alt="cruz verde"
              title="cruz verde"
              src="/img/partners/cruzverde.svg"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
          <div className="partner">
            <img
              alt="Fundación Solidaridad por Colombia"
              title="Fundación Solidaridad por Colombia"
              src="/img/partners/fundacion-solidaridad-por-colombia-logo.svg"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
          <div className="partner">
            <img
              alt="GVNG"
              title="GVNG"
              src="/img/partners/GVNG-logo.svg"
              className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
            />
          </div>
        </div>
      </div>
    </section> */}
    {/*<section className="team">*/}
    {/*<div className="container">*/}
    {/*<div className="col-md-12">*/}
    {/*<h2>The <span>Team</span></h2>*/}
    {/*<br/>*/}
    {/*</div>*/}
    {/*<Team title={"The"} strong={"Team"}/>*/}
    {/*</div>*/}
    {/*</section>*/}
  </Layout>
);

export default IndexPage;
